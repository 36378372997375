<template>
  <div>
    <div class="box">
      <div class="title_box flex">
        <div class="flex">
          <h3 :class="title == '账单明细' ? 'TitleHover' : 'h3'" @click="get_list('账单明细')">
            账单明细
          </h3>
        </div>
      </div>
      <div class="ct_box">
        <div class="search_status_box flex">
          <div class="search_box flex">
            <order_time @get_time="get_time" />
          </div>
        </div>
        <table class="table">
          <thead>
            <tr>
              <td>ID</td>
              <!-- <td>订单号</td> -->
              <!-- <td>媒介名</td> -->
              <td>金额</td>
              <td>钱包余额</td>
              <td>账户余额</td>
              <td>类型</td>
              <td>分类</td>
              <td>下单时间</td>
              <td>备注</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in listTable" :key="index">
              <td>{{ item.id }}</td>
              <!-- <td>{{ item.sn }}</td>
              <td>{{ item.m_title}}</td> -->
              <td :style="{
            color: item.type == 1 || item.type == 3 || (item.type == 4 && item.style == 1) || (item.type == 5 && item.style == 1) || (item.type == 14 && item.style == 2) || (item.type == 15 && item.style == 1) || (item.type == 16 &&
              item.style == 1) ? '#00B052' : '#FF0011'
          }">

                {{ item.type == 1 || item.type == 3 || (item.type == 4 && item.style == 1) || (item.type == 5 &&
            item.style == 1 && item.style ==
            1) || (item.type == 14 && item.style == 2) || (item.type == 15 && item.style == 1) || (item.type == 16
              &&
              item.style == 1) ? '+' : '-' }}{{
            item.amount }}
              </td>
              <td>{{ item.wallet }}</td>
              <td>{{ item.money }}</td>
              <td>{{ typeList[item.type] }}</td>
              <td>{{ styleList[item.style] }}</td>
              <td>{{ item.ctime }}</td>
              <td class="over_text">
                <p class="sn" v-if="item.sn">
                  订单号：{{ item.sn }}
                </p>
                <p class="sn" v-if="item.m_title">
                  媒介名：{{ item.m_title }}

                </p>
                <td_remark :text="item.remark" />
              </td>
            </tr>
          </tbody>
        </table>
        <!-- 分页器 -->
        <pagination :page="page" @pageChange="pageChange" :count="count" :total_page="total_page" />
      </div>
    </div>
  </div>
</template>

<script>
import order_time from '@/components/order_time'
export default {
  mounted() {
    // setTimeout(() => {
    this.get_list();
    // }, 200)
  },
  components: {
    order_time
  },
  data() {
    return {
      title: "账单明细",
      time: [],
      resource: "",
      orderId: "",
      status: "全部",
      listTable: [],
      typeList: {
        1: "充值",
        2: "下单",
        3: "订单收入",
        4: "退稿",
        5: "撤稿",
        6: "提现",
        7: "转化",
        8: "提现手续费",
        9: "税金",
        10: 'AI扣费',
        11: '拒稿',
        12: '扣款',
        13: '溢价',
        14: '同意溢价',
        15: '拒绝溢价',
        16: '同意溢价退款',
      },
      styleList: { 1: "余额", 2: "钱包" },
      total_page: 1, //分页总页数
      count: 1, //总条数
      page: 1,
      searchTime: "",
    };
  },
  methods: {
    pageChange(pageVal) {
      this.page = pageVal
      this.get_list()
    },
    statusShow(item) {
      this.status = item;
    },
    titleShow(item) {
      this.title = item;
    },
    get_time(time) {
      this.time = time || []
      this.pageChange(1)
    },
    get_list() {
      let where = { page: this.page, limt: 20 };
      if (this.time.length) {
        where.start_time = this.time[0] / 1000;
        where.end_time = this.time[1] / 1000;
      }
      // 如果有token传token 如果没有则不传
      this.curlGet("/api/users/money_log", where).then((res) => {
        if (res.data.code) {
          console.log(res.data);
          this.listTable = res.data.data.list
          this.total_page = res.data.data.total_page
          this.count = res.data.data.count
        }
      });
    },
    // 上一页
    prevClick() {
      console.log("up");
      this.page -= 1;
      this.get_list();
    },
    // 切换页
    currentTab(val) {
      console.log("切换");
      this.page = val;
      this.get_list();
    },
    // 下一页
    nextClick() {
      console.log("down");
      this.page += 1;
      this.get_list();
    },
    // 筛选
    searchBut() {
      this.get_list();
    },

  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/AccountDetails";
</style>